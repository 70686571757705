import React, { useEffect, useState } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';
import { exportToBlob } from '@excalidraw/excalidraw';
import { MainMenu } from '@excalidraw/excalidraw';
import { defaultLang, languages } from "@excalidraw/excalidraw";
import { Tooltip } from 'chart.js';

const ExcalidrawComponent = ({ contentId, initialData, readOnly, userLanguages }) => {
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  const [isCollaborating, setIsCollaborating] = useState(false);

  const mappedLanguages = userLanguages.length > 0 ? userLanguages.map(lang => {
    // exemple of userLanguages => {code: 'en', label: 'English'}
    // lang is a string like 'fr' not 'fr-FR'
    // We need to find the language in the languages array
    return languages.find(language => language.code.startsWith(lang));
  }) : [defaultLang];

  const UiOptions = {
    canvasActions: {
      loadScene: false,
      saveScene: false,
      saveAsScene: false,
      export: false,
      clearCanvas: false,
      theme: false,
      viewModeEnabled: false,
      zenModeEnabled: false,
      gridModeEnabled: false,
      focusModeEnabled: false,
      libraryEnabled: false,
    },
    tools: {
      image: false,
    },
  };

  // We need to expose saveDrawingToBlob to the window 
  // so that we can call it from the Stimulus controller
  useEffect(() => {
    if (!readOnly) {
      window.saveDrawingToBlob = saveDrawingToBlob;
    }
  }, [excalidrawAPI]);

  const saveDrawingToBlob = async () => {
    if (excalidrawAPI) {
      const blob = await exportToBlob({
        elements: excalidrawAPI.getSceneElements(),
        mimeType: 'image/png',
      });
      return blob;
    }
    return null;
  };

  const handleDrawingChange = (elements, appState) => {
    const newDrawingData = { elements, appState };
    const input = document.getElementById(`input-excalidraw-data-${contentId}`);

    if (input) {
      input.value = JSON.stringify(newDrawingData);
      input.dispatchEvent(new Event('change'));
    }
  };

  const calculateHeight = () => {
    const element = readOnly ? document.getElementById(`drawing-read-container-${contentId}`) : document.getElementById(`drawing-write-container-${contentId}`);
    const height = window.innerHeight;
    const top = element.getBoundingClientRect().top;
    const result = height - top - 100;
    const roundedResult = Math.round(result / 4) * 4;

    return `${roundedResult}px`;
  }

  return (
    <div>
      <div style={{ height: calculateHeight() }} id={`drawing-${readOnly ? 'read' : 'write'}-wrapper-container-${contentId}`}>
        <Excalidraw
          initialData={initialData}
          onChange={handleDrawingChange}
          UIOptions={UiOptions}
          excalidrawAPI={api => setExcalidrawAPI(api)}
          isCollaborating={isCollaborating}
          viewModeEnabled={readOnly}
          zenModeEnabled={readOnly}
          langCode={mappedLanguages[0].code} >
          {readOnly && (
            <MainMenu>
              <MainMenu.DefaultItems.SaveAsImage />
              <MainMenu.DefaultItems.Help />
            </MainMenu>
          )}
        </Excalidraw>
      </div>
    </div>
  );
};

export default ExcalidrawComponent;
