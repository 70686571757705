import React from 'react';
import { createRoot } from 'react-dom/client';
import ExcalidrawComponent from '../react_components/ExcalidrawComponent';

document.addEventListener('DOMContentLoaded', () => {
  const processElements = (elements, roots) => {
    elements.forEach(element => {
      const contentId = element.id.split('-').pop();
      const initialData = element.dataset.initialData ? JSON.parse(element.dataset.initialData) : null;
      const readOnly = element.dataset.readOnly === 'true';
      const userLanguages = element.dataset.languages ? JSON.parse(element.dataset.languages) : ['fr'];

      if (!roots[element.id]) {
        const root = createRoot(element);
        roots[element.id] = root;
      }

      roots[element.id].render(
        <ExcalidrawComponent 
          contentId={contentId} 
          initialData={initialData} 
          isCollaborating={false} 
          readOnly={readOnly} 
          userLanguages={userLanguages} 
        />
      );
    });
  };

  const drawingWriteElements = document.querySelectorAll('[id^="drawing-write-container-"]');
  const drawingReadElements = document.querySelectorAll('[id^="drawing-read-container-"]');
  const writeRoots = {};
  const readRoots = {};

  processElements(drawingWriteElements, writeRoots);
  processElements(drawingReadElements, readRoots);
});
